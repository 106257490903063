<template>
  <v-container class="missing-config mx-auto pt-12" fluid>
    <!--    <h1>CONFIGURATION</h1>-->


    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-size: 90px !important;" class="font-weight-bolder primary--text">ERROR</h1>
        <h5 class="font-weight-light">This application required configuration from admin side, be patient and try later
          <b>:(</b></h5>
      </v-col>
      <v-col cols="12">
        <div class="gears">
          <div class="gear one">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
          <div class="gear two">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
          <div class="gear three">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
          <v-btn link to="/" large color="primary">
            <v-icon class="mr-3">mdi-refresh</v-icon>
            Try again
          </v-btn>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "missingConfig"
}
</script>

<style>
/**/
:root {
  --main-color: #eaeaea;
  --stroke-color: black;
}

.missing-config > h1 {
  margin: 100px auto 0 auto;
  color: var(--stroke-color);
  font-family: "Encode Sans Semi Condensed", Verdana, sans-serif;
  font-size: 10rem;
  line-height: 10rem;
  font-weight: 200;
  text-align: center;
}

.missing-config > h2 {
  margin: 20px auto 30px auto;
  font-family: "Encode Sans Semi Condensed", Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
}

.missing-config > h1,
.missing-config > h2 {
  -webkit-transition: opacity 0.5s linear, margin-top 0.5s linear; /* Safari */
  transition: opacity 0.5s linear, margin-top 0.5s linear;
}

.missing-config > .loading h1,
.missing-config > .loading h2 {
  margin-top: 0px;
  opacity: 0;
}

.gears {
  position: relative;
  margin: 0 auto;
  width: auto;
  height: 0;
}

.gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: var(--stroke-color);
}

.gear:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  content: "";
  border-radius: 50%;
  background: var(--main-color);
}

.gear:after {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 3;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid var(--stroke-color);
  box-sizing: border-box;
  background: var(--main-color);
}

.gear.one {
  left: -130px;
}

.gear.two {
  top: -75px;
}

.gear.three {
  top: -235px;
  left: 130px;
}

.gear .bar {
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 0;
  width: 150px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: var(--stroke-color);
}

.gear .bar:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  content: "";
  border-radius: 2px;
  background: var(--main-color);
}

.gear .bar:nth-child(2) {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.gear .bar:nth-child(3) {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
}

@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes anticlockwise {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes clockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(30deg);
  }
  40% {
    -webkit-transform: rotate(25deg);
  }
  60% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes anticlockwiseErrorStop {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-30deg);
  }
  60% {
    -webkit-transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-webkit-keyframes anticlockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-30deg);
  }
  40% {
    -webkit-transform: rotate(-25deg);
  }
  60% {
    -webkit-transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

.gear.one {
  -webkit-animation: anticlockwiseErrorStop 2s linear infinite;
}

.gear.two {
  -webkit-animation: anticlockwiseError 2s linear infinite;
}

.gear.three {
  -webkit-animation: clockwiseError 2s linear infinite;
}

.loading .gear.one,
.loading .gear.three {
  -webkit-animation: clockwise 3s linear infinite;
}

.loading .gear.two {
  -webkit-animation: anticlockwise 3s linear infinite;
}

</style>
